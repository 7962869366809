import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Completed from '../views/Completed.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import AcceptableUsePolicy from '../views/AcceptableUsePolicy.vue'
import Pricing from '../views/Pricing.vue'
import FAQ from '../views/FAQ.vue'
import SampleReports from '../views/SampleReports.vue'
import Competition from '../views/Competition.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/completed',
    name: 'Completed',
    component: Completed
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/competition',
    name: 'Competition',
    component: Competition
  },
  {
    path: '/sample-report',
    name: 'Sample Reports',
    component: SampleReports
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/acceptable-use-policy',
    name: 'Acceptable Use Policy',
    component: AcceptableUsePolicy
  },
  {
    path: '/valuation',
    name: 'Valuation',
    component: () => import('../views/GetAValuation.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition

    if (to.hash) return { selector: to.hash }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const referral = to.query.tmReferral
  if (referral) {
    localStorage.setItem(
      'tmReferral',
      Array.isArray(referral) ? referral[0] : referral
    )
  }

  next()
})

export default router
